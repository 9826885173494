import CreateAccount from '../pages/account/createAccount';
import ListAccounts from '../pages/account/listAccounts';
import EditAccount from '../pages/account/editAccount';
import CreateUser from '../pages/user/createUser';
import ListUsers from '../pages/user/listUsers';
import ListPending from '../pages/user/listPending';
import ListDeactivated from '../pages/user/listDeactivated';
import EditUser from '../pages/user/editUser';
import createMeeting from '../pages/meetings/createMeeting';
import editMeeting from '../pages/meetings/editMeeting';
import CreateLesson from 'pages/courses/createLesson';
import Lesson from 'pages/courses/lesson';
import Lessons from 'pages/courses/lessons';
import ArchivedLessons from 'pages/courses/archivedLessons';
import CreateCourse from 'pages/courses/createCourse';
import CreateTest from 'pages/courses/createTest';
import Tests from 'pages/courses/tests';
import CourseTest from 'pages/courses/courseTest';
import EditTest from 'pages/courses/editTest';
import CoursesList from 'pages/courses/coursesList';
import CoursesLibrary from 'pages/courses/coursesLibrary';
import CourseHome from 'pages/courses/courseHome';
import CourseRedirect from 'pages/courses/courseRedirect';
import CourseLesson from 'pages/courses/courseLesson';
import EditCourse from 'pages/courses/editCourse';
import EditLesson from 'pages/courses/editLesson';
import Certificate from 'pages/courses/certificate';

const adminRoutes = [
  // User Management
  { path: '/create-user', component: CreateUser },
  { path: '/list-users', component: ListUsers },
  { path: '/list-pending', component: ListPending },
  { path: '/list-deactivated', component: ListDeactivated },
  { path: '/edit-user', component: EditUser },

  // Account Management
  { path: '/create-account', component: CreateAccount },
  { path: '/list-accounts', component: ListAccounts },
  { path: '/edit-account', component: EditAccount },

  // Meeting Management
  { path: '/create-meeting', component: createMeeting },
  { path: '/edit-meeting', component: editMeeting },

  // Lesson Management
  { path: '/create-course', component: CreateCourse },
  { path: '/edit-course', component: EditCourse },
  { path: '/create-lesson', component: CreateLesson },
  { path: '/create-test', component: CreateTest },
  { path: '/course/:id/test', component: CourseTest },
  { path: '/tests', component: Tests },
  { path: '/edit-test', component: EditTest },
  { path: '/lesson', component: Lesson },
  { path: '/lessons', component: Lessons },
  { path: '/edit-lesson', component: EditLesson },
  { path: '/archived-lessons', component: ArchivedLessons },
  { path: '/courses', component: CoursesList },
  { path: '/library', component: CoursesLibrary },
  { path: '/course/:id/lesson', component: CourseLesson },
  { path: '/course/:id/', component: CourseRedirect },
  { path: '/course', component: CourseHome },
  { path: '/course/:id/certificate', component: Certificate },
];

export { adminRoutes };

import React, { useState, useEffect, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';

import { useAuth } from 'hooks/useAuth';
import Breadcrumbs from '../../components/Breadcrumb';
import SuccessModal from '../../components/Success-Modal';
import LabelGroup from '../../components/Label-Group';
import TextInput from '../../components/Text-Input';
import DateInput from '../../components/Date-Input';
import MarkdownInput from '../../components/Markdown-Input';
import SelectInput from '../../components/Select-Input';
import SpinnerInput from '.../../components/Spinner-Input';
import NextSteps from '../../components/Next-Steps';
import SubmitButton from '../../components/Submit-Button';
import { getFormValue, getEpochDate, loadTeam, loadTasks, normalizeSteps } from '../../utils/';
import { getFirebaseBackend } from '../../helpers/firebaseHelper';
import '../../assets/scss/custom/components/_dragndrop.scss';

const MeetingsCreate = () => {
  const formConfig = {
    defaultValues: {
      agenda: '',
      date: '',
      description: '',
      participants: [],
      relatedTasks: [],
      videoLink: '',
      steps: [{ assignedTo: '', step: '' }],
      minutes: 60,
    },
    shouldFocusError: true,
    criteriaMode: 'all',
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  };

  const firebaseHelper = getFirebaseBackend();

  const form = useForm(formConfig);
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const accountId = user.account;
  const [meetingId, setMeetingId] = useState();
  const [successModal, setSuccessModal] = useState(false);
  const [assignedKey, setAssignedKey] = useState(0);
  const [participantsKey, setParticipantsKey] = useState(1000);
  const [tasksKey, setTasksKey] = useState(2000);
  const meetingIdRef = useRef();

  const { clearErrors } = form;
  meetingIdRef.current = meetingId;

  useEffect(async () => {
    const currentMeetingId = await firebaseHelper.newMeetingKey(accountId);
    setMeetingId(currentMeetingId);

    return () => {
      firebaseHelper.removeUnusedMDFiles({
        accountId,
        id: meetingIdRef.current,
      });
    };
  }, []);

  useEffect(() => {
    setAssignedKey((prevState) => prevState + 1);
    setParticipantsKey((prevState) => prevState + 1);
    setTasksKey((prevState) => prevState + 1);
  }, [accountId]);

  const closeModalHandle = () => {
    form.reset();
    setSuccessModal(false);
  };

  const onSubmit = async (data) => {
    const meeting = {
      id: meetingId,
      accountId,
      agenda: data.agenda,
      description: data.description !== '' ? data.description : null,
      date: getEpochDate(data.date),
      minutes: data.minutes,
      videoLink: data.videoLink,
      steps: normalizeSteps(data.steps),
      participants: data.participants.map(getFormValue),
      relatedTasks: data.relatedTasks ? data.relatedTasks.map(getFormValue) : null,
    };

    if (data.description.length !== 0) {
      clearErrors('description');
    }
    if (data.participants.length !== 0) {
      clearErrors('participants');
    }

    await firebaseHelper.registerMeeting(meeting);
    // Get new Task Id for the next possible task to be created
    const newMeetingKey = firebaseHelper.newMeetingKey(accountId);
    setMeetingId((prevState) => newMeetingKey);
    // Opens Modal to give feedback about succesfull meeting register
    setSuccessModal(true);
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{`${translate('Create Meeting')} | Ectools`}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={translate('Meetings')} breadcrumbItem={translate('Create Meeting')} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <SuccessModal
                    isOpen={successModal}
                    onClose={closeModalHandle}
                    verb="Created"
                    buttonText="See Meetings"
                    buttonLink="/meetings"
                  />

                  <CardTitle className="mb-4">{translate('Create New Meeting Log')}</CardTitle>

                  <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                      <LabelGroup htmlFor="date" label="Date">
                        <DateInput controlName="date" placeholder="Meeting Date" size={4} />
                        <SpinnerInput
                          controlName="minutes"
                          label="Minutes Long"
                          required
                          size={4}
                          min={1}
                        />
                      </LabelGroup>

                      <LabelGroup htmlFor="agenda" label="Meeting Agenda" required>
                        <TextInput controlName="agenda" placeholder="Meeting Agenda" required />
                      </LabelGroup>

                      <LabelGroup htmlFor="description" label="Description" required>
                        <MarkdownInput attachId={meetingId} controlName="description" required />
                      </LabelGroup>

                      <NextSteps
                        key={assignedKey}
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue) => loadTeam(accountId, inputValue)}
                      />

                      <LabelGroup htmlFor="participants" label="Participants" required>
                        <SelectInput
                          asyncronous
                          isMulti
                          controlName="participants"
                          key={participantsKey}
                          required
                          cacheOptions
                          defaultOptions
                          loadOptions={(inputValue) => loadTeam(accountId, inputValue)}
                        />
                      </LabelGroup>

                      <LabelGroup htmlFor="videoLink" label="Video Link">
                        <TextInput
                          controlName="videoLink"
                          placeholder="Type the Meeting Video URL (Vimeo or Youtube)"
                          example="https://vimeo.com/1234567890"
                          // Regex pattern to only match vimeo and youtube URLS
                          validation={{
                            pattern: {
                              value:
                                /(https?:\/\/(.+?\.)?(vimeo|youtube)\.com(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/,
                              message: 'The URL needs to be from Vimeo or Youtube',
                            },
                          }}
                        />
                      </LabelGroup>

                      <LabelGroup htmlFor="relatedTasks" label="Related Tasks">
                        <SelectInput
                          asyncronous
                          isMulti
                          controlName="relatedTasks"
                          key={tasksKey}
                          cacheOptions
                          defaultOptions
                          loadOptions={(inputValue) => loadTasks(accountId, inputValue)}
                        />
                      </LabelGroup>

                      <SubmitButton text="Create Log" />
                    </form>
                  </FormProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MeetingsCreate;
